<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="app-wrapper">
    <BaseNavigation />
    <DividerNavigation />
    <BaseBottomNavigation />
    <div class="p-2 mx-auto">
      <b-card
        class="shadow-none border p-1 mb-1"
        no-body
        style="cursor: pointer"
      >
        <div class="d-flex flex-row align-items-center">
          <b-img
            :src="imageForumSimfoni"
            height="50"
            width="50"
            rounded="circle"
            class="mr-1"
          />
          <b-button
            :variant="`${!toogleTambahForum ? 'outline-' : ''}danger`"
            block
            @click="toogleTambahForum = !toogleTambahForum"
          >
            {{ toogleTambahForum ? "Batalkan " : "Tambah Forum" }}
          </b-button>
        </div>
      </b-card>

      <b-form v-if="toogleTambahForum" @submit.prevent>
        <validation-observer ref="createForumValidate">
          <validation-provider
            #default="{ errors }"
            name="Content Forum"
            rules="required"
          >
            <quill-editor
              v-model="content"
              :options="snowOption"
              :state="errors.length > 0 ? false : null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
          <b-form-group label="Gambar" label-for="gambar" class="mt-1">
            <validation-provider
              #default="{ errors }"
              name="Gambar Forum"
              rules="required"
            >
              <b-form-file
                id="gambar"
                v-model="fileGambar"
                :state="errors.length > 0 ? false : null"
                accept="image/*"
                @change="onFileChange($event)"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
            <div v-if="fileGambar" class="mt-1">
              <b-img
                height="200"
                width="200"
                class="mt-1"
                fluid
                :src="gambar"
              />
              <b-button
                v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                variant="flat-success"
                class="btn-icon rounded-circle ml-25"
                @click="(fileGambar = null), (gambar = null)"
              >
                <feather-icon icon="TrashIcon" stroke-width="2" />
              </b-button>
            </div>
          </b-form-group>
        </validation-observer>
        <b-button
          type="submit"
          variant="outline-danger"
          block
          @click="validationFormCreateForum"
        >
          Tambah
        </b-button>
      </b-form>
      <br />
      <CardPost
        v-for="forum in forums.data"
        :key="forum.id"
        :forum="forum"
        is-elipsis="true"
        @refreshFetch="fetchForums"
      />
      <div v-if="forums.isLoading" class="d-flex justify-content-center mb-1">
        <b-spinner label="Loading..." variant="danger" />
      </div>
      <div
        v-if="!forums.isLoading && !forums.data.length"
        class="d-flex justify-content-center mb-1"
      >
        Belum ada forum yang di posting
      </div>
    </div>
    <DividerNavigation />
  </div>
</template>

<script>
/* eslint-disable global-require */
// eslint-disable-next-line
import "quill/dist/quill.core.css";
// eslint-disable-next-line
import "quill/dist/quill.snow.css";
// eslint-disable-next-line
import "quill/dist/quill.bubble.css";

import {
  BSpinner,
  BCard,
  BImg,
  BModal,
  BButton,
  BForm,
  BFormFile,
  BFormGroup,
  BFormInput,
} from "bootstrap-vue";
import BaseNavigation from "@/components/Base/BaseNavigation.vue";
import DividerNavigation from "@/components/Base/DividerNavigation.vue";
import BaseBottomNavigation from "@/components/Base/BaseBottomNavigation.vue";
import CardPost from "@/components/Forum/CardPost.vue";
import ButtonCreateNewPost from "@/components/Forum/ButtonCreateNewPost.vue";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { quillEditor } from "vue-quill-editor";
import { required } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import apis from "@/api";

export default {
  components: {
    BaseNavigation,
    DividerNavigation,
    BaseBottomNavigation,
    ValidationProvider,
    ValidationObserver,
    ToastificationContent,
    BSpinner,
    BCard,
    quillEditor,
    BImg,
    BModal,
    BButton,
    BForm,
    BFormFile,
    BFormGroup,
    BFormInput,
    CardPost,
    ButtonCreateNewPost,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      kripIcon: require("@/assets/images/icons/icon-stethoscope.png"),
      rekomendasiIcon: require("@/assets/images/icons/icon-documents.png"),
      keanggotaanIcon: require("@/assets/images/icons/icon-idcard.png"),
      avatar: require("@/assets/images/avatars/1.png"),
      examplePicStudiKasus: require("@/assets/images/pages/Studi_Kasus_Contoh.jpeg"),
      imageForumSimfoni: require("@/assets/images/logo/logo-new-idi.png"),

      forums: {
        isLoading: false,
        data: [],
        nextPageUrl: null,
      },
      content: "",
      snowOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            [{ list: "ordered" }, { list: "bullet" }],
          ],
        },
        theme: "snow",
        placeholder: "Silahkan tulis konten forum disini ...",
      },
      gambar: null,
      fileGambar: null,
      toogleTambahForum: null,
    };
  },
  mounted() {
    document
      .getElementsByClassName("app-wrapper")[0]
      .addEventListener("scroll", this.scrollCallback);
  },
  created() {
    this.fetchForums();
  },
  methods: {
    scrollCallback() {
      const element = document.getElementsByClassName("app-wrapper")[0];
      const { scrollTop } = element;
      const { scrollHeight } = element;
      const { clientHeight } = element;
      if (scrollTop + clientHeight + 100 >= scrollHeight) {
        if (!this.forums.isLoading && this.forums.nextPageUrl) {
          this.fetchForums(this.forums.nextPageUrl);
        }
      }
    },
    refreshFetch() {
      this.forums.nextPageUrl = null;
      this.fetchForums();
    },
    fetchForums(url) {
      // TODO: set pagination scrollable
      this.forums.isLoading = true;
      apis.forum
        .getAll(url)
        .then(({ data }) => {
          if (url) {
            this.forums.data = this.forums.data.concat(data.data);
          } else {
            this.forums.data = data.data;
          }
          this.forums.isLoading = false;
          this.forums.nextPageUrl = data.next_page_url;
        })
        .catch(() => {});
    },
    //methods forums
    validationFormCreateForum() {
      this.$refs.createForumValidate.validate().then((success) => {
        if (success) {
          this.tambahStudiKasus();
        }
      });
    },

    tambahStudiKasus() {
      if (!this.content) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Konten forum wajib diisi",
            icon: "AlertCircleIcon",
            variant: "danger",
            setTimeout: "5000",
          },
        });
        return;
      }
      this.$store.commit("app/UPDATE_LOADING_BLOCK", true);

      apis.forum
        .addForum({
          forum_content: this.content,
          forum_image: this.gambar,
        })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Berhasil menambahkan forum",
              icon: "CheckIcon",
              variant: "success",
            },
          });
          this.toogleTambahForum = false;
          this.fetchForums();
        })
        .catch((error) => {
          this.errorHandler(
            error,
            "menambahkan forum gagal, silahkan coba lagi nanti"
          );
        })
        .finally(() => {
          this.$store.commit("app/UPDATE_LOADING_BLOCK", false);
        });
    },
    onFileChange(e) {
      const { files } = e.target;
      if (files.length) {
        this.createImage(files[0], (result) => {
          this.gambar = result;
        });
      }
    },
    createImage(file, cb) {
      const reader = new FileReader();

      reader.onload = (e) => {
        cb(e.target.result);
      };
      reader.readAsDataURL(file);
    },
  },
};
</script>
